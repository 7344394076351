import React from "react"

import styles from "./Hero.css"

const Hero = ({
  title,
  children,
  style,
  svg,
  imageWidth,
  contentfulImageObjectFitted,
}) => {
  console.log(contentfulImageObjectFitted)
  return (
    <div className={"heroWrapper"}>
      <div
        className={`${"mainWrapper"} ${svg && styles[svg]} ${
          imageWidth && styles[imageWidth]
        } ${svg}`}
      >
        {contentfulImageObjectFitted && (
          <div className={"contentfulImageFitted"}>
            <img
              className="coverImage"
              src={`https:${contentfulImageObjectFitted.file.url}`}
              width={contentfulImageObjectFitted.file.details.image.width}
              height={contentfulImageObjectFitted.file.details.image.height}
            />
          </div>
        )}
        <div className={`${"hero"} ${style && styles[style]}`}>
          <div
            className={`${"wrapper"} ${
              contentfulImageObjectFitted
                ? "wrapperContentfulImageObjectFitted"
                : ""
            }`}
          >
            {title && <h1 dangerouslySetInnerHTML={{ __html: title }} />}
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
