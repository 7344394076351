import React, { useState } from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled, { createGlobalStyle } from "styled-components"
import { Link, graphql } from "gatsby"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import CourseHero from "../components/CourseHero"
import Img from "gatsby-image"
import EventItem from "../components/EventItem"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Helmet } from "react-helmet"
import Grid from "../components/Grid"
import Hero from "../components/Hero"

export const query = graphql`
  query($slug: String!) {
    contentfulPodcastEpisode(slug: { eq: $slug }) {
      id
      anchor
      audio
      duration
      episode
      guests
      podcast
      season
      slug
      title
      youTube
      description {
        json
      }
      release
      cover {
        fluid {
          srcSetWebp
        }
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
      }
    }
  }
`

const GlobalStyle = createGlobalStyle`
/* body {
  background-color: #f7f7f7;
} */
`

const StyledPodcast = styled.div`
  .heading {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  a {
    color: var(--primary);
  }

  .iframeContainer {
    position: relative;
    padding-bottom: 56.25% /* 16:9 */;
    height: 0;
    margin: 2rem auto;
    // margin: 5rem auto;

    // @media (min-width: 768px) {
    //   margin: 10rem auto;
    // }
  }

  .iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .anchor {
    margin-bottom: 5px;
    a {
      font-weight: bold;
      &:hover {
        color: var(--primary);
      }
    }
  }

  .heroTitle {
    background-color: #e63600;
    box-shadow: -3px 3px #b32a00;
    padding: 0.25rem 0.5rem;
    color: white;
    margin-left: 3px;
  }

  .heroEpisode {
    background-color: #16a5b3;
    box-shadow: -3px 3px #2c8b93;
    padding: 0.25rem 0.5rem;
    color: white;
    margin-left: 1rem;
  }

  .back {
    max-width: 1200px;
    margin: 2rem 1rem;

    @media (min-width: 1200px) {
      margin: 2rem auto;
    }

    a:hover {
      color: var(--primary);
    }
  }

  .description {
    p {
      margin-bottom: 1rem;
    }
  }

  main {
    max-width: 1200px;
    margin-inline: auto;
  }

  @media (max-width: 1200px) {
    main {
      margin: auto 0rem;
      padding: 0 1rem;
    }
  }
`

const getGuestString = guests => {
  if (!guests.length) return ""
  if (guests.length === 1) return `mit ${guests[0]}`
  if (guests.length === 2) return `mit ${guests[0]} und ${guests[1]}`

  return `mit ${guests.slice(0, -1).join(", ")} und ${guests.slice(-1)}`
}

const Podcast = props => {
  const {
    title,
    heroTitle,
    heroEpisode,
    episode,
    season,
    audio,
    youTube,
    anchor,
    description,
    guests,
    slug,
    cover,
  } = props.data.contentfulPodcastEpisode

  return (
    <Layout>
      <Helmet>
        <link
          rel="canonical"
          href={`https://www.i40.de/digikompetenzpodcast/${slug}`}
        />
      </Helmet>
      <GlobalStyle />
      <SEO title={title} />
      <StyledPodcast>
        <div>
          <Hero
            title={title}
            // style='topRight'
            imageWidth="half"
            contentfulImageObjectFitted={cover}
          >
            <div>
              <span className="heroTitle">DigiKompetenz Podcast</span>
              <span className="heroEpisode">
                Episode {season === 1 ? episode : episode + 29}
              </span>
            </div>
            {/* <iframe
              src={audio}
              height="102px"
              width="100%"
              frameBorder="0"
              scrolling="no"
            ></iframe> */}
            <Grid gap="1" layout="third" style={{ alignItems: "center" }}>
              <a
                href="https://podcasts.apple.com/us/podcast/digikompetenz-podcast/id1560764627"
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginBottom: "0" }}
              >
                <img
                  src="https://www.i40.de/images/digikompetenzpodcast/DE_Apple_Podcasts_Listen_Badge_RGB.svg"
                  alt="Auf Apple Podcasts anhören"
                />
              </a>
              <a
                href="https://open.spotify.com/show/64Wt6MZ5FVphqq3YCYLPkK"
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginBottom: "0" }}
              >
                <img
                  src="https://www.i40.de/images/digikompetenzpodcast/spotify-podcast-badge-blk-grn-165x40.svg"
                  alt="Spotify"
                />
              </a>
              <span className="anchor">
                <a
                  href={anchor}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginBottom: "0" }}
                >
                  Weitere Plattformen
                </a>
              </span>
            </Grid>
          </Hero>
          <div className="back">
            <Link
              to="/digikompetenzpodcast"
              style={{
                display: "flex",
                alignItems: "center",
                color: "var(--primary)",
              }}
            >
              &lt; Zurück zur Episodenübersicht
            </Link>
          </div>

          <main>
            <div className="heading">
              <h3>{getGuestString(guests)}</h3>
            </div>
            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: documentToHtmlString(description.json),
              }}
            />
            {youTube && (
              <>
                <h4 style={{ marginTop: "3rem" }}>Video ansehen</h4>
                <div className="iframeContainer">
                  <iframe
                    className="iframe"
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${youTube.substr(
                      -11
                    )}?rel=0`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </>
            )}
            <div style={{ marginTop: "3rem" }}></div>
          </main>
        </div>
      </StyledPodcast>
    </Layout>
  )
}

Podcast.propTypes = {
  data: PropTypes.object,
}

export default Podcast
