import React from "react"
import "./Grid.css"

const Grid = ({ children, layout, gap, ...props }) => {
  return (
    <div
      className={`grid ${layout && `layout--${layout}`} ${
        gap && `gap--${gap}`
      }`}
      {...props}
    >
      {children}
    </div>
  )
}

export default Grid
